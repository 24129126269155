

































import { Vue, Component, Watch } from "vue-property-decorator";
import { mdiPhone, mdiViewDashboard } from "@mdi/js";
import WDay from "@/components/WDay.vue";
import { User } from "@/models/user";
import { db } from "@/plugins/firebase";
import manager from "@/services/dayManager";
import { getDocs, Unsubscribe, collection, onSnapshot, writeBatch, DocumentSnapshot, doc, setDoc } from "firebase/firestore";
import type { User as FirebaseUser } from "firebase/auth";

@Component({
    name: "Course",
    components: {
        WDay
    }
})
export default class Course extends Vue {
    private readonly manager = manager;
    private progress: User | null = null;

    async created() {
        if (!this.$store.state.user && this.$store.state.loading == false) {
            this.$router.push({ name: "Home" });
            return;
        }
    }

    beforeDestroy() {
        if (this.unsub)
            this.unsub();
    }

    public readonly icons = {
        mdiPhone,
        mdiViewDashboard
    }

    private completeSnackbar = false;

    get name() {
        return this.$store.state.user?.displayName;
    }

    private unsub: Unsubscribe | null = null;

    @Watch("$store.state.user", { immediate: true })
    public async onUserChange(user: FirebaseUser) {
        if (this.unsub)
            this.unsub();
        if (!user) return;
        const daysCollection = await getDocs(collection(db, "users", user.uid, "days"));
        if (daysCollection.empty) {
            const batch = writeBatch(db);
            for (const day of this.manager.keys) {
                batch.set(doc(db, "users", user.uid, "days", day), {
                    day,
                    done: false,
                    current: null,
                    max: null,
                    startDate: null,
                    completeDate: null
                });
            }
            batch.commit();
        }
        this.unsub = onSnapshot(doc(db, "users", user.uid), async (d: DocumentSnapshot) => {
            this.progress = { id: d.id, ...d.data() } as User;
            if (!this.progress.active)
                this.$router.push({ name: "Dashboard" });
        });
    }

    private async onEnd() {
        setDoc(doc(db, "users", this.$store.state.user.uid), { active: null }, { merge: true });
        this.completeSnackbar = true;
        this.$router.push({ name: "Dashboard" });
    }
}
